import VideoPlayer from './VideoPlayer';
import SwipeUpHint from './SwipeUpHint';
import { Swiper, SwiperSlide } from 'swiper/react';
import playButton from '../icons/playButton.svg'

function VideoList(props) {
  return (
    <Swiper onSlidePrevTransitionStart={(swiper) => {if (!props.videos.find(v => v.numberId === swiper.activeIndex)) {swiper.slideNext()}}} onSlideChangeTransitionEnd={(swiper) => props.setSlideId(swiper.activeIndex, swiper)} direction={'vertical'} className="mySwiper">
      {props.videos.map((vid, index) => (
        <SwiperSlide key={index}>
          <VideoPlayer 
            id = {vid.id}
            options={
              {
                aspectRatio: '9:16',
                preload: 'auto',
                autoplay: false,
                controls: false,
                responsive: true,
                fluid: true,
                loop: true,
                muted: false,
                sources: [{
                  src: vid.src,
                  type: vid.type
                }]
              }
            } />
            {vid.numberId === 0 &&
              <>
                <img onClick={()=> props.playSlideVideo()}className="first-video-play-button" src={playButton} />
                <SwipeUpHint showHint={props.showHint}/>
              </>
            }
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default VideoList;