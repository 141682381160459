import arrowUp from "../icons/arrowUp.svg";

function SwipeUpHint(props) {
  if (props.showHint) {
    return (
      <div className="swipe-hint">
        <img width="64" height="64" src={arrowUp} />
        <p>Swipe UP</p>
      </div>
    )
  } else {
    return <></>;
  }
}

export default SwipeUpHint;