import React, { useState, useEffect } from "react";
import VideoList from "./components/VideoList";
import videojs from "video.js";
import axios from "axios";

import './App.css';
import "swiper/css";

function App() {
  const [initialLoaded, setIsInitialLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [videoList, setVideoList] = useState([]);
  const [slideId, setSlideId] = useState(0);
  const [showHint, setShowHint] = useState(true);
  const { id } = useQueryParams();
  const { name } = useQueryParams();
  const [videoFirstIndex, setVideoFirstIndex] = useState(0);

  function useQueryParams() {
    const params = new URLSearchParams(
      window ? window.location.search : {}
    );

    return new Proxy(params, {
        get(target, prop) {
            return target.get(prop)
        },
    });
  }

  useEffect(() => {
    let playerList = videojs.getAllPlayers();
    if (playerList.length > 0) {
      let firstPlayer = playerList[0];

      if (firstPlayer) {
        firstPlayer.ready(function() {
          firstPlayer.on('play', () => {
            firstPlayer.controls = false;
            firstPlayer.muted = false;
            let firstPlayButton = document.querySelector('.first-video-play-button');
            if (firstPlayButton) {
              firstPlayButton.style["display"] = "none";
            }
          })
        })
      }
    }

  }, [initialLoaded]);

  useEffect(() => {
    playSlideVideo();
    console.log(videoFirstIndex)

  }, [slideId]);

  useEffect(() => {
    if (isLoading) {
      console.log('Video ID: ',id);
      console.log('here');
      if (name) {
        console.log('query name=' + name);
        axios.get('https://api.myvideo.fun/get_videos?name='+name)
        .then(function (response) {
          let newVideoList = [];
          let playerIndex = 0;
          response.data.forEach(video => {
            let newVideo = {
              numberId: playerIndex,
              id: 'video-player'+playerIndex,
              src: video.url+"#t=0.001",
              type: 'application/x-mpegURL',
              name: video.filename
            };
            newVideoList.push(newVideo);
            playerIndex++;
          });
          console.log(newVideoList);
          appendAddressBar(newVideoList[0].name);
          setVideoList(newVideoList);
          setIsLoading(false);
          setIsInitialLoaded(true);
        })
        .catch(function (error) {
          console.log(error);
        })
      } else if (id) {
        console.log('query id=' + id);
        axios.get('https://api.myvideo.fun/get_videos?id='+id)
        .then(function (response) {
          let newVideoList = [];
          let playerIndex = 0;
          response.data.forEach(video => {
            let newVideo = {
              numberId: playerIndex,
              id: 'video-player'+playerIndex,
              src: video.url+"#t=0.001",
              type: 'application/x-mpegURL',
              name: video.filename
            };
            newVideoList.push(newVideo);
            playerIndex++;
          });
          console.log(newVideoList);
          appendAddressBar(newVideoList[0].name);
          setVideoList(newVideoList);
          setIsLoading(false);
          setIsInitialLoaded(true);
        })
        .catch(function (error) {
          console.log(error);
        })
      } else {
        console.log('without params');
        axios.get('https://api.myvideo.fun/get_videos')
          .then(function (response) {
            let newVideoList = [];
            let playerIndex = 0;
            response.data.forEach(video => {
              let newVideo = {
                numberId: playerIndex,
                id: 'video-player'+playerIndex,
                src: video.url+"#t=0.001",
                type: 'application/x-mpegURL',
                name: video.filename
              };
              newVideoList.push(newVideo);
              playerIndex++;
            });
            appendAddressBar(newVideoList[0].name);
            setVideoList(newVideoList);
            setIsLoading(false);
            setIsInitialLoaded(true);
          })
          .catch(function (error) {
            console.log(error);
          })
      }
    }
  }, []);

  function loadNextVideos() {
    axios.get('https://api.myvideo.fun/get_videos')
      .then(function (response) {
        let newVideoList = [];
        let playerIndex = videoList.length > 0 ? Math.max(...videoList.map(i => i.numberId)) + 1 : 0;
        console.log('player Index: ', playerIndex);
        response.data.forEach(video => {
          let newVideo = {
            numberId: playerIndex,
            id: 'video-player'+playerIndex,
            src: video.url+"#t=0.001",
            type: 'application/x-mpegURL',
            name: video.filename
          };
          newVideoList.push(newVideo);
          playerIndex++;
        });
        setVideoList([...videoList, ...newVideoList]);
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  function appendAddressBar(videoName) {
    console.log(window.history);

    let newVideoName = "/?name="+videoName;

    if (window.history.pushState) {
      window.history.pushState("object or string", "Title", newVideoName);
    } else {
      document.location.href = newVideoName;
    }
  }

  function playSlideVideo() {
    let playerList = videojs.getAllPlayers();

    playerList.forEach((player) => {
      console.log(player);
      if (Number(player.id().replace('video-player', '')) === slideId) {

        let videoName = videoList.find((vl) => vl.src === player.options_.sources[0].src);
        console.log(videoName);
        if (videoName) {
          appendAddressBar(videoName.name);
        }

        // Safari
        let ua = navigator.userAgent.toLowerCase();
        let is_safari = (ua.indexOf("safari/") > -1 && ua.indexOf("chrome") < 0);
        if(is_safari) {
          let videoId = player.id()+'_html5_api';
          let video = document.getElementById(videoId);
            setTimeout(() => {
              let playPromise = video.play();
              video.firstClick = true;

              video.addEventListener('play', (event) => {
                video.controls = false;
              });

              if (playPromise !== undefined) {
                playPromise.then(_ => {
                  video.controls = false;
                })
                .catch(error => {
                  video.controls = true;
                });
              }
            }, 50);

        }      
        else {
          player.ready(() => {
            setTimeout(function() {
              player.play();
            }, 50);
          })
        }
      } else {
        player.pause();
        player.currentTime(0);
      }
    });

    let videoIndex = videoList.findIndex((vl) => Number(vl.id.replace('video-player', '')) === slideId);

    if (videoIndex > 4) {
      setVideoFirstIndex(videoIndex - 5);
    }

    if (slideId === videoList.length-1) {
      loadNextVideos();
    }
    
    if (slideId !== 0) {
      setShowHint(false);
    }

    let firstPlayButton = document.querySelector('.first-video-play-button');

    if (firstPlayButton) {
      firstPlayButton.style["display"] = "none";
    }
  }

  useEffect(() => {
    let playerList = videojs.getAllPlayers();

    let newVideoList = videoList;

    for (let i = 0; i < videoFirstIndex; i++) {
      let player = playerList.find((p) => Number(p.id().replace('video-player', '')) === i);

      if (player) {
        player.removeClass('vjs-seeking');
      }
      
      newVideoList[i].numberId = -1;
      console.log(i);
      var videoElement = document.getElementById('video-player'+i+'_html5_api');
      videoElement.removeAttribute('src');
      videoElement.load();
    }

    setVideoList(newVideoList);
  }, [videoFirstIndex])

  function updateSlideId(slideId, swiper) {
    setSlideId(slideId);
  }

  return (
    <div className="App">
      {videoList.length > 0 ?
        <VideoList videos={videoList} setSlideId={updateSlideId} showHint={showHint} videoFirstIndex={videoFirstIndex} playSlideVideo={playSlideVideo} />
       :
      <div>Nothing</div>
      }
    </div>
  );
}

export default App;
